import React from "react";
import { Typography } from "@mui/material";

export const EmptyPageTemplete = ({ image, heading, message }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "70vh",
          textAlign: "center",
        }}
      >
        <img
          src={image}
          alt="empty"
          style={{ width: "200px", height: "200px" }}
        />
        <Typography
          style={{
            fontFamily: "Poppins",
            fontSize: "18px",
            fontWeight: "600",
            color: "#111827",
            textAlign: "center",
            fontStyle: "normal",
            lineHeight: "120%",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          {heading}
        </Typography>
        <Typography
          style={{
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: "400",
            paddingRight: "10px",
            paddingLeft: "10px",
            color: "#6B7280",
            textAlign: "center",
            fontStyle: "normal",
            lineHeight: "160%",
          }}
        >
          {message}
        </Typography>
      </div>
    </>
  );
};
