import React from "react";
import {
  CustomVendorContainer,
  VendorSubTypography,
  ViewStatusBlock,
} from "../../Utils/VendorStyledTags";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import VendorBottomNavbar from "../../../../layouts/VendorBottomNavbar";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useState } from "react";
import { ADDITIONAL_CHARGE_FIELD } from "../../Utils/VendorStyledObjects";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { axiosVendorServiceInstance } from "../../../../services/api";
import { toast } from "react-toastify";
import TopNavBarWebView from "../../../../layouts/TopNavBarWebView";
import { useVendor } from "../../../../contexts/VendorContext";

export default function POSCartScreen() {
  const isLargeScreen = useMediaQuery("(min-width:1069px)");
  const isMediumScreen = useMediaQuery(
    "(min-width:700px) and (max-width:1069px)"
  );
  const { vendorPOSCharges } = useVendor();
  const isBoth = isLargeScreen || isMediumScreen;

  const location = useLocation();
  const navigate = useNavigate();
  // const vendorPOSCharges = location.state === null ? [] : location.state;

  const vendor = JSON.parse(localStorage.getItem("user"));

  const currencyCode = vendor.currencySymbol;

  const [additionalCharges, setAdditionalCharges] = useState(
    vendorPOSCharges.length > 0
      ? vendorPOSCharges
      : [{ name: "", charge: "", type: "AMOUNT" }]
  );

  const handleAdditionalChargeTypeChange = (index, event) => {
    const newCharges = [...additionalCharges];
    newCharges[index].type = event.target.value;
    setAdditionalCharges(newCharges);
  };

  const handleAddAnAdditionalCharge = () => {
    setAdditionalCharges([
      ...additionalCharges,
      { name: "", charge: "", type: "AMOUNT" },
    ]);
  };

  const handleRemoveVariant = (index) => {
    const newCharges = additionalCharges.filter((_, i) => i !== index);
    setAdditionalCharges(newCharges);
  };

  const handleClickBackIcon = () => {
    navigate("/vendor/vendorService");
  };

  const handleClickCancel = () => {
    if (isBoth) {
      navigate("/vendor/vendorPOSAdditionalCharges");
    } else {
      navigate("/vendor/vendorService");
    }
  };

  const handleClickSave = async () => {
    const cleanedCharges = additionalCharges.filter(
      (charge) =>
        charge.name.trim() !== "" && String(charge.charge).trim() !== ""
    );

    try {
      await axiosVendorServiceInstance.post(
        `/vendor-service/update-pos-charges?userId=${JSON.parse(
          localStorage.getItem("userId")
        )}`,
        cleanedCharges
      );

      navigate("/vendor/vendorUpdatingPOSChargesSuccess");
      setTimeout(() => {
        if (isBoth) {
          navigate("/vendor/vendorPOSAdditionalCharges");
        } else {
          navigate("/vendor/vendorService");
        }
      }, 1500);
    } catch (err) {
      if (err.response === undefined) {
        alert("Please Try Again Later!");
      } else {
        const errmessage = err.response.data.error;
        toast.error(errmessage);
      }
    }
  };

  const isValidPositiveNumber = (value) => {
    const regex = /^\d*\.?\d*$/;
    return regex.test(value);
  };

  const handleChargeChange = (index, e) => {
    const { value } = e.target;
    if (isValidPositiveNumber(value)) {
      const newCharges = [...additionalCharges];
      newCharges[index].charge = value;
      setAdditionalCharges(newCharges);
    }
  };

  return (
    <>
      <CssBaseline />
      <main>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "0px",
            background: "var(--gray-50, #F9FAFB)",
          }}
        >
          {isBoth && <TopNavBarWebView index={8} />}
          <CustomVendorContainer>
            <Container
              maxWidth="sm"
              style={{
                zIndex: 10,
                padding: isBoth ? "0px 0px 12px" : "0px",
                margin: isLargeScreen
                  ? `40px calc((100% - 1060px) / 2) 40px calc(((100% - 1060px) / 2) + 400px)`
                  : "0px auto",
                border: isBoth ? "1px solid var(--gray-200, #E5E7EB)" : "none",
                background: "#FFF",
                borderRadius: "20px",
                marginBottom: "80px",
                minHeight: "80vh",
              }}
            >
              <Container maxWidth="sm">
                <ViewStatusBlock
                  style={{
                    justifyContent: isBoth ? "space-between" : "center",
                    borderBottom: isBoth
                      ? "1px solid var(--gray-200, #E5E7EB)"
                      : "none",
                    paddingBottom: isBoth ? "16px" : "0px",
                    position: "relative",
                  }}
                >
                  {!isBoth && (
                    <ArrowBackIosIcon
                      onClick={handleClickBackIcon}
                      sx={{
                        position: "absolute",
                        top: isBoth ? 5 : 0,
                        left: 0,
                      }}
                    />
                  )}

                  <Typography
                    style={{
                      fontWeight: isBoth ? 600 : 500,
                      color: "#1C1C1C",
                      fontFamily: "Poppins",
                      fontSize: isBoth ? "28px" : "18px",
                      margin: isBoth ? " 0px 0px" : "0px",
                      lineHeight: "130%",
                      textTransform: "capitalize",
                    }}
                  >
                    {" "}
                    POS - Additional Charges
                  </Typography>
                </ViewStatusBlock>

                <Container
                  style={{
                    marginTop: isBoth ? "20px" : "70px",
                    padding: "0px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#4B5563",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      textAlign: "left",
                      marginBottom: "20px",
                    }}
                  >
                    Organize your additional charges to apply them in the order
                    process.
                  </Typography>

                  {additionalCharges.map((additionalCharge, index) => (
                    <Container
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        padding: "0px",
                        marginBottom: "10px",
                      }}
                    >
                      <TextField
                        sx={{ ...ADDITIONAL_CHARGE_FIELD, marginRight: "10px" }}
                        placeholder="Charge Name"
                        value={additionalCharge.name}
                        onChange={(e) => {
                          const newCharges = [...additionalCharges];
                          newCharges[index].name = e.target.value;
                          setAdditionalCharges(newCharges);
                        }}
                      />

                      <TextField
                        sx={{ ...ADDITIONAL_CHARGE_FIELD, marginRight: "10px" }}
                        placeholder="Charge"
                        value={additionalCharge.charge}
                        onChange={(e) => handleChargeChange(index, e)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  height: "46px",
                                  borderRadius: "6px 0px 0px 6px",
                                  borderRight:
                                    "1px solid var(--gray-300, #D1D5DB)",
                                  background: "var(--gray-50, #F9FAFB)",
                                }}
                              >
                                <Select
                                  value={additionalCharge.type}
                                  onChange={(event) =>
                                    handleAdditionalChargeTypeChange(
                                      index,
                                      event
                                    )
                                  }
                                  displayEmpty
                                  sx={{
                                    backgroundColor: "#F9FAFB",
                                    borderRadius: "6px 0px 0px 6px",
                                    "& .MuiSelect-select": {
                                      padding: "0px",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      border: "none",
                                    },
                                  }}
                                >
                                  <MenuItem value="AMOUNT">
                                    {currencyCode}
                                  </MenuItem>
                                  <MenuItem value="PERCENTAGE">%</MenuItem>
                                </Select>
                              </Box>
                            </InputAdornment>
                          ),
                        }}
                      />

                      {index === additionalCharges.length - 1 ? (
                        <AddCircleIcon
                          onClick={handleAddAnAdditionalCharge}
                          sx={{
                            width: "50px",
                            height: "50px",
                            color: "var(--green-600, #059669)",
                          }}
                        />
                      ) : (
                        <RemoveCircleIcon
                          onClick={() => handleRemoveVariant(index)}
                          sx={{
                            width: "50px",
                            height: "50px",
                            color: "var(--gray-800, #1F2937)",
                          }}
                        />
                      )}
                    </Container>
                  ))}

                  <Grid
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "16px",
                      marginTop: "100px",
                      marginBottom: "24px",
                    }}
                  >
                    <Button
                      onClick={handleClickCancel}
                      style={{
                        borderRadius: "6px",
                        background: "#111827",
                        padding: "16px 13px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "48%",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#FFF",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          lineHeight: "16px",
                          textTransform: "capitalize",
                        }}
                      >
                        Cancel
                      </Typography>
                    </Button>

                    <Button
                      onClick={handleClickSave}
                      style={{
                        borderRadius: "6px",
                        background: "#059669",
                        padding: "16px 13px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "48%",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#FFF",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          lineHeight: "16px",
                          textTransform: "capitalize",
                        }}
                      >
                        Save
                      </Typography>
                    </Button>
                  </Grid>
                </Container>
              </Container>
              {!isBoth && <VendorBottomNavbar clickedIndex={2} />}{" "}
            </Container>
          </CustomVendorContainer>
        </Box>
      </main>
    </>
  );
}
