import React, { useState } from "react";
import LocalDrinkOutlinedIcon from "@mui/icons-material/LocalDrinkOutlined";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import EventNoteIcon from "@mui/icons-material/EventNote";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { BottomNavigationAction, Container, Typography } from "@mui/material";
import RedeemIcon from "@mui/icons-material/Redeem";
import { Link } from "react-router-dom";
import ReceiptLongRoundedIcon from '@mui/icons-material/ReceiptLongRounded';

export default function VendorBottomNavbar(props) {
  const globalColor = JSON.parse(localStorage.getItem("user"))["themeColour"];
  const [clickedIndex] = useState(
    props.clickedIndex === undefined ? 0 : props.clickedIndex
  );

  const navBarContents = [
    { to: "/vendor/allOrders", icon: LocalDrinkOutlinedIcon, label: "Orders" },
    { to: "/vendor/vendorAllItems", icon: EventNoteIcon, label: "Menu" },
    {
      to: "/vendor/vendorService",
      icon: LayersOutlinedIcon,
      label: "Services",
    },
    { to: "/vendor/vendorAllOffers", icon: RedeemIcon, label: "Offers" },
    { to: "/vendor/POSHome", icon: ReceiptLongRoundedIcon, label: "POS" },
    {
      to: "/vendor/vendorProfile",
      icon: AccountCircleOutlinedIcon,
      label: "Profile",
    },
  ];

  return (
    <Container
      maxWidth="sm"
      style={{
        display: "flex",
        padding: "0px",
        justifyContent: "space-between",
        position: "fixed",
        bottom: 0,
        width: "100%",
        background: "#FFF",
        boxShadow: "0px -4px 16px 6px rgba(55, 55, 56, 0.08)",
        borderRadius: "12px 12px 0px 0px",
      }}
    >
      {navBarContents.map((navBarContent, index) => (
        <Link
          key={index}
          to={navBarContent.to}
          style={{
            textDecoration: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flex: "1 1 0",
            padding: "0px",
          }}
        >
          <BottomNavigationAction
            style={{
              padding: "0px",
              minWidth: "0px",
            }}
            icon={
              <navBarContent.icon
                style={{
                  width: "30px",
                  height: "30px",
                  color: clickedIndex === index ? globalColor : "#111827",
                }}
              />
            }
          />
          <Typography
            style={{
              fontFamily: "Poppins",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "130%",
              textTransform: "capitalize",
              color: clickedIndex === index ? globalColor : "#111827",
            }}
          >
            {navBarContent.label}
          </Typography>
        </Link>
      ))}
    </Container>
  );
}
