import React from "react";
import { Routes, Route } from "react-router-dom";
import { ServiceOpeningHours } from "../components/Vendor/Services/Screen/ServiceOpeningHours";
import { VendorManageDispatchMode } from "../components/Vendor/Services/Screen/VendorManageDispatchMode";

import { VendorProvider } from "./VendorContext";
import { VendorManageDeiveryAreaFeeScreen } from "../components/Vendor/Services/Screen/VendorManageDeiveryAreaFeeScreen";
import DeliveryForm from "../components/Vendor/Services/Screen/DeliveryForm";
import { useThemeConfig } from "../themeConfig";
import { VendorManagePaymentMethodScreen } from "../components/Vendor/Services/Screen/VendorManagePaymentMethodScreen";
import VendorPOSAdditionalCharges from "../components/Vendor/Services/Screen/VendorPOSAdditionalCharges";
import { DeliveryConfigurations } from "../components/Vendor/Services/Screen/DeliveryConfigurations";

const VendorRoutes = () => {
  const { globalColor } = useThemeConfig();

  return (
    <VendorProvider>
      <Routes>
        <Route
          path="/vendor/ServiceOpeningHours"
          element={<ServiceOpeningHours />}
        />
        <Route
          path="/vendor/DeliveryAreaFee"
          element={<DeliveryForm themeColor={globalColor} />}
        />

        <Route
          path="/vendor/vendorManageDispatchMode"
          element={<VendorManageDispatchMode />}
        />
        <Route
          path="/vendor/vendorManagePaymentMethod"
          element={<VendorManagePaymentMethodScreen themeColor={globalColor} />}
        />

        <Route
          path="/vendor/vendorPOSAdditionalCharges"
          element={<VendorPOSAdditionalCharges />}
        />

        <Route
          path="/vendor/vendorDeliveryConfigurations"
          element={<DeliveryConfigurations themeColor={globalColor} />}
        />
      </Routes>
    </VendorProvider>
  );
};

export default VendorRoutes;
