import {
  Button,
  Container,
  Grid,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { FullscreenExit } from "@mui/icons-material";
import { VendorWebViewInfoDialogBox } from "../../../../../Styles";

export default function ManageTakeAwayModeConfirmation(props) {
  const isLargeScreen = useMediaQuery("(min-width:1069px)");
  const isMediumScreen = useMediaQuery(
    "(min-width:700px) and (max-width:1069px)"
  );
  const isBoth = isLargeScreen || isMediumScreen;
  const dialogMessage = props.confimationStatusData;
  const [isDialogOpen, setIsDialogOpen] = props.statusChangeState;

  const handleClickCancelButton = () => {
    setIsDialogOpen(false);
  };

  const handleClickConfirmButton = () => {
    props.changeTakeawayStatus();
    setIsDialogOpen(false);
  };

  const Wrapper = !isBoth ? SwipeableDrawer : VendorWebViewInfoDialogBox;

  const wrapperProps = !isBoth
    ? {
        PaperProps: {
          sx: { backgroundColor: "transparent", boxShadow: "none" },
        },
        anchor: "bottom",
        open: isDialogOpen,
        sx: { width: "100%", maxWidth: "100%", background: "transparent" },
        "aria-setsize": FullscreenExit,
      }
    : {
        open: isDialogOpen,
      };
  return (
    <Wrapper {...wrapperProps}>
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "40px 18px 52px 18px",
          borderRadius: "32px 32px 0px 0px",
          background: "#FFF",
        }}
      >
        <Typography
          sx={{
            color: "#111827",
            fontFamily: "Poppins",
            fontSize: "22px",
            fontWeight: 600,
            lineHeight: "130%",
            textAlign: "center",
            fontStyle: "normal",
          }}
        >
          {dialogMessage.dialogHeading}
        </Typography>{" "}
        <Typography
          style={{
            color: "#6B7280",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "160%",
            marginTop: "16px",
            marginBottom: "40px",
          }}
        >
          {dialogMessage.dialogContent}{" "}
        </Typography>
        <Grid
          container
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={handleClickCancelButton}
            sx={{
              textTransform: "capitalize",
              padding: "14px 25px",
              borderRadius: "6px",
              background: "#111827",
              width: "48%",
            }}
          >
            <Typography
              style={{
                color: "var(--white, var(--white, #FFF))",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "150%",
              }}
            >
              Cancel
            </Typography>
          </Button>

          <Button
            onClick={handleClickConfirmButton}
            sx={{
              textTransform: "capitalize",
              padding: "14px 25px",
              borderRadius: "6px",
              background: "#EF4444",
              width: "48%",
            }}
          >
            <Typography
              sx={{
                color: "var(--white, var(--white, #FFF))",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "150%",
              }}
            >
              Confirm
            </Typography>
          </Button>
        </Grid>
      </Container>
    </Wrapper>
  );
}
