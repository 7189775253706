import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import {
  AllMainTypography,
  CustomVendorContainer,
  ViewStatusBlock,
} from "../../Utils/VendorStyledTags";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { axiosVendorServiceInstance } from "../../../../services/api";
import { toast } from "react-toastify";

export const DeliveryRestrictionTime = ({ buffertime }) => {
  const isLargeScreen = useMediaQuery("(min-width:1349px)");
  const isMediumScreen = useMediaQuery(
    "(min-width:710px) and (max-width:1349px)"
  );
  const isBoth = isLargeScreen || isMediumScreen;
  const location = useLocation();
  const navigate = useNavigate();
  const [bufferTime, setBufferTime] = React.useState(
    dayjs(`2022-04-17T${location.state ? location.state : buffertime}`)
  );
  console.log(location.state);

  const handleConfirm = async () => {
    const token = localStorage.getItem("authToken");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    if (bufferTime) {
      const formattedTime = bufferTime.format("HH:mm");
      try {
        await axiosVendorServiceInstance.post(
          `/vendor-service/update-delivery-restriction-time?userId=${JSON.parse(
            localStorage.getItem("userId")
          )}&bufferTime=${formattedTime}`
        );
        navigate("/vendor/vendorUpdatingDeliveryRestrictionTimeSuccess");
        setTimeout(() => {
          if (isBoth) {
            navigate("/vendor/vendorDeliveryConfigurations");
          } else {
            navigate("/vendor/vendorService");
          }
        }, 1500);
      } catch (err) {
        if (err.response === undefined) {
          alert("Please Try Again Later!");
        } else {
          const errmessage = err.response.data.error;
          toast.error(errmessage);
        }
      }
    } else {
      console.log("No buffer time selected");
    }
  };

  const handleClickBackIcon = () => {
    navigate("/vendor/vendorDeliveryConfigurations", {
      state: location.state,
    });
  };

  return (
    <>
      <CssBaseline />
      <main>
        <CustomVendorContainer style={{ background: "#FFF" }}>
          <Container
            maxWidth="sm"
            style={{
              padding: isBoth ? "0px" : "12px 0px",
              zIndex: "10",
              height: "100%",
            }}
          >
            <Container maxWidth="sm">
              <ViewStatusBlock
                style={{
                  justifyContent: isBoth ? "space-between" : "center",
                  paddingBottom: "0px",
                  margin: "0px 0px 20px",
                  position: "relative",
                }}
              >
                {!isBoth && (
                  <IconButton
                    onClick={handleClickBackIcon}
                    color="inherit"
                    sx={{
                      position: "absolute",
                      top: isBoth ? 10 : -10,
                      left: 0,
                    }}
                  >
                    <ArrowBackIosIcon />
                  </IconButton>
                )}

                <Typography
                  style={{
                    fontWeight: 500,
                    color: "#1C1C1C",
                    fontFamily: "Poppins",
                    fontSize: isBoth ? "20px" : "18px",
                    textAlign: "left",
                    lineHeight: "130%",
                    textTransform: "capitalize",
                  }}
                >
                  Delivery Restriction Time
                </Typography>
              </ViewStatusBlock>{" "}
              <Typography
                variant="body1"
                sx={{
                  color: "var(--gray-600, #4B5563)",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  textAlign: "left",
                }}
              >
                Customers can't place an order for a particular delivery
                schedule once the restriction time has passed.
              </Typography>
              <Typography
                sx={{
                  color: "#374151",
                  fontSize: "16px",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  textAlign: isBoth ? "left" : "center",
                  marginTop: "33px",
                  marginBottom: "6px",
                }}
              >
                Set restriction time
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: isBoth ? "flex-start" : "center",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopTimePicker
                    sx={{ textAlign: "left" }}
                    views={["hours", "minutes"]}
                    value={bufferTime}
                    onChange={(newValue) => setBufferTime(newValue)}
                    format="HH:mm"
                    ampm={false}
                  />
                </LocalizationProvider>
              </Box>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "16px",
                  marginTop: "30px",
                }}
              >
                <Button
                  style={{
                    borderRadius: "6px",
                    background: "var(--gray-900, #111827)",
                    alignContent: "flex-start",
                    width: "50%",
                    padding: "16px 13px",
                  }}
                  onClick={handleClickBackIcon}
                >
                  <Typography
                    style={{
                      color: "var(--white, #FFF)",
                      textAlign: "center",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "18px" /* 112.5% */,
                      textTransform: "capitalize",
                    }}
                  >
                    Cancel
                  </Typography>
                </Button>
                <Button
                  style={{
                    borderRadius: "6px",
                    background: "var(--green-600, #059669)",
                    alignContent: "flex-start",
                    width: "50%",
                    padding: "10px 13px",
                  }}
                  onClick={handleConfirm}
                >
                  <Typography
                    style={{
                      color: "var(--white, #FFF)",
                      textAlign: "center",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "18px" /* 112.5% */,
                      textTransform: "capitalize",
                    }}
                  >
                    Save
                  </Typography>
                </Button>
              </Grid>
            </Container>
          </Container>
        </CustomVendorContainer>
      </main>
    </>
  );
};
