import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import {
  AllMainTypography,
  CustomVendorContainer,
  ViewStatusBlock,
} from "../../Utils/VendorStyledTags";
import AddHomeWorkOutlinedIcon from "@mui/icons-material/AddHomeWorkOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import LockClockOutlinedIcon from "@mui/icons-material/LockClockOutlined";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useLocation, useNavigate } from "react-router-dom";
import { DeliveryRestrictionTime } from "./DeliveryRestrictionTime";
import { VendorManageDeiveryAreaFeeScreen } from "./VendorManageDeiveryAreaFeeScreen";
import TopNavBarWebView from "../../../../layouts/TopNavBarWebView";
import DeliveryForm from "./DeliveryForm";
import { useVendor } from "../../../../contexts/VendorContext";

export const DeliveryConfigurations = ({ themeColor }) => {
  const isLargeScreen = useMediaQuery("(min-width:1349px)");
  const isMediumScreen = useMediaQuery(
    "(min-width:710px) and (max-width:1349px)"
  );
  const isBoth = isLargeScreen || isMediumScreen;

  const location = useLocation();
  const navigate = useNavigate();
  const [selectedDeliveryConfiguration, setSelectedDeliveryConfiguration] =
    useState(0);

  const { areasAndDeliveryFee, bufferTime } = useVendor();

  const handleClickManageDeliveryArea = () => {
    if (isBoth) {
      setSelectedDeliveryConfiguration(0);
    } else {
      if (
        JSON.parse(localStorage.getItem("user"))["vendorCategory"] ===
        "RESTAURANT"
      ) {
        navigate("/vendor/vendorManageDeliveryAreaFee", {
          state: areasAndDeliveryFee,
        });
      } else {
        navigate("/vendor/DeliveryAreaFee", {
          state: areasAndDeliveryFee,
        });
      }
    }
  };

  const handleClickDeliveryRestrictionTime = () => {
    if (isBoth) {
      setSelectedDeliveryConfiguration(1);
    } else {
      navigate("/vendor/vendorDeliveryRestrictionTime", {
        state: bufferTime,
      });
    }
  };

  const handleClickBackIcon = () => {
    navigate("/vendor/vendorService");
  };

  return (
    <>
      {(isLargeScreen || isMediumScreen) && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "0px",
            background: "#FFFF",
          }}
        >
          <TopNavBarWebView index={4} />
          <Grid
            container
            sx={{ display: "flex", flexDirection: "row", padding: "0px" }}
          >
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                margin: isLargeScreen
                  ? `40px calc((100% - 1360px) / 2) 40px calc(((100% - 1360px) / 2) + 400px)`
                  : "40px auto",
                border: "1px solid var(--gray-200, #E5E7EB)",
                background: "#FFF",
                borderRadius: "20px",
              }}
            >
              <Typography
                sx={{
                  color: "var(--gray-900, #111827)",
                  fontFamily: "Poppins",
                  fontSize: "28px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "130%",
                  padding: "16px",
                  textAlign: "left",
                  borderBottom: "1px solid var(--gray-200, #E5E7EB)",
                }}
              >
                Delivery configuration{" "}
              </Typography>

              <Grid
                sx={{
                  display: "flex",
                  marginRight: "1px solid black",
                  padding: "0px",
                }}
              >
                <Container
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    width: "306px",
                    padding: "24px 12px",
                    cursor: "pointer",
                    flexDirection: "column",
                    gap: "16px",
                    borderRight: "1px solid var(--gray-200, #E5E7EB)",
                  }}
                >
                  <Button
                    onClick={handleClickManageDeliveryArea}
                    variant="contained"
                    style={{
                      backgroundColor:
                        selectedDeliveryConfiguration === 0
                          ? themeColor
                          : "#FFFF",
                      color:
                        selectedDeliveryConfiguration === 0
                          ? "var(--white, #FFF)"
                          : "var(--gray-700, #374151)",
                      width: "100%",
                      textTransform: "none",
                      justifyContent: "space-between",
                      gap: "8px",
                      padding: "8px 10px",
                      boxShadow: "none",
                    }}
                  >
                    Delivery area{" "}
                  </Button>
                  <Button
                    onClick={handleClickDeliveryRestrictionTime}
                    variant="contained"
                    style={{
                      backgroundColor:
                        selectedDeliveryConfiguration === 1
                          ? themeColor
                          : "#FFFF",

                      color:
                        selectedDeliveryConfiguration === 1
                          ? "var(--white, #FFF)"
                          : "var(--gray-700, #374151)",
                      width: "100%",
                      textTransform: "none",
                      justifyContent: "space-between",
                      gap: "8px",
                      padding: "8px 10px",
                      boxShadow: "none",
                    }}
                  >
                    Delivery restriction time{" "}
                  </Button>
                </Container>{" "}
                <Container
                  maxWidth="sm"
                  style={{
                    width: "100%",
                    background: "#FFF",
                    margin: "24px 12px ",
                    padding: "0px",
                  }}
                >
                  {" "}
                  {selectedDeliveryConfiguration === 0 &&
                    (JSON.parse(localStorage.getItem("user"))[
                      "vendorCategory"
                    ] === "RESTAURANT" ? (
                      <VendorManageDeiveryAreaFeeScreen
                        areasAndDeliveryFee={areasAndDeliveryFee}
                      />
                    ) : (
                      <DeliveryForm
                        areasAndDeliveryFee={areasAndDeliveryFee}
                        themeColor={themeColor}
                      />
                    ))}
                  {selectedDeliveryConfiguration === 1 && (
                    <DeliveryRestrictionTime buffertime={bufferTime} />
                  )}
                </Container>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
      <CssBaseline />
      <main>
        {!isBoth && (
          <CustomVendorContainer>
            <Container
              maxWidth="sm"
              style={{ zIndex: 10, padding: "0px", background: "#FFF" }}
            >
              <Container maxWidth="sm">
                <ViewStatusBlock
                  style={{
                    justifyContent: isBoth ? "space-between" : "center",
                    alignItems: "center",
                    borderBottom: isBoth
                      ? "1px solid var(--gray-200, #E5E7EB)"
                      : "none",
                    paddingBottom: isBoth ? "16px" : "0px",
                    position: "relative",
                  }}
                >
                  <ArrowBackIosIcon
                    onClick={handleClickBackIcon}
                    sx={{
                      position: "absolute",
                      top: isBoth ? 5 : 0,
                      left: 0,
                    }}
                  />

                  <Typography
                    style={{
                      fontWeight: isBoth ? 600 : 500,
                      color: "#1C1C1C",
                      fontFamily: "Poppins",
                      fontSize: isBoth ? "28px" : "18px",
                      margin: isBoth ? " 0px 30px" : "0px",
                      lineHeight: "130%",
                      textTransform: "capitalize",
                    }}
                  >
                    Delivery Configurations
                  </Typography>
                </ViewStatusBlock>

                <Grid
                  container
                  sx={{
                    padding: "0px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Grid
                    onClick={handleClickManageDeliveryArea}
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "16px 0px",
                      alignItems: "center",
                      borderBottom: "1px solid var(--gray-200, #E5E7EB)",
                    }}
                  >
                    <Grid sx={{ display: "flex", alignItems: "center" }}>
                      <PinDropOutlinedIcon
                        sx={{
                          marginRight: "12px",
                          width: "32px",
                          height: "32px",
                          color: "#111827",
                        }}
                      />
                      <Typography
                        style={{
                          color: "#111827",
                          fontFamily: "Poppins",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          lineHeight: "18px",
                        }}
                      >
                        Delivery area
                      </Typography>
                    </Grid>
                    <Grid>
                      <ArrowForwardIosOutlinedIcon />
                    </Grid>
                  </Grid>
                  <Grid
                    onClick={handleClickDeliveryRestrictionTime}
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "16px 0px",
                      alignItems: "center",
                      borderBottom: "1px solid var(--gray-200, #E5E7EB)",
                    }}
                  >
                    <Grid sx={{ display: "flex", alignItems: "center" }}>
                      <LockClockOutlinedIcon
                        sx={{
                          marginRight: "12px",
                          width: "32px",
                          height: "32px",
                          color: "#111827",
                        }}
                      />
                      <Typography
                        style={{
                          color: "#111827",
                          fontFamily: "Poppins",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          lineHeight: "18px",
                        }}
                      >
                        Delivery restriction time
                      </Typography>
                    </Grid>
                    <Grid>
                      <ArrowForwardIosOutlinedIcon />
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </Container>
          </CustomVendorContainer>
        )}
      </main>
    </>
  );
};
